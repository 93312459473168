<template>
    <OApryseEditor 
        readOnly tagRecognizer
        :annotsDataObject="props.annotsDataObject"
        :pdfSrc="{ viewName: 'aviw_Arena_DocumentsDetail', primKey: `${props.primKey}`, fileName: '1.pdf' }"
        ref="apryseRef"
        @EditorMounted="editorMounted"
    >
        <template #header-top-left>
            <i class="bi bi-magic" @click="() => {searchModalRef.show()}" v-tooltip="{title: 'Search for tags', placement: 'bottom', trigger: 'hover', delay: { show: 600, hide: 100 }}"></i>
        </template>
    </OApryseEditor>
    <OObjectsLookup ref="objLookupRef" :bind="objectSelected" />
    <OModal ref="searchModalRef">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('Search For Tags')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ORowContainer class="row-container">
                        <o-data-grid :data-object="props.syntaxDataObject" filter-row hideGridMenu disable-batch-records noFooter hideMultiselectColumn :showNewRecordsPanel="false">
                            <o-column field="Name" headerName="Syntax" :width="350" editable sortable flexWidth="1" :filter="false"></o-column>
                        </o-data-grid>
                    </ORowContainer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                    <button type="button" class="btn btn-primary" @click="searchForTags">
                        {{$t('Search For Tags')}}
                    </button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import type { DataObject } from 'o365-dataobject';
    import { ref } from 'vue';
    import { OApryseEditor } from 'o365-apryse';
    import { OObjectsLookup } from 'o365-system-lookups';
    import { vTooltip, useDataObjectEventListener } from 'o365-vue-utils';
    import { alert as o365alert} from 'o365-vue-services';
    import TagRecognizer from 'arena.modules.TagRecognizer.ts';

    export interface Props {
        primKey: string
        annotsDataObject: DataObject
        objectsDataObject: DataObject
        syntaxDataObject?: DataObject
    };
    
    const props = defineProps<Props>();
    const apryseRef = ref();
    const objLookupRef = ref();
    const searchModalRef = ref();
    var instance: WebViewerInstance = undefined;
    var lastSelectedAnnotation = undefined;

    useDataObjectEventListener(props.annotsDataObject, 'BeforeSave', function(event, updated, row) {
        let object_json = instance.Core.annotationManager.getAnnotationsList()
                    .find(annot => annot.Id == row.AnnotID)
                    .getCustomData('Object')
        
        if(object_json){
            event.values.Object_ID = JSON.parse(object_json).ObjectRequirement_ID
        }
    }); 

    function editorMounted(viewer: WebViewerInstance){
        instance = viewer;
        instance.Core.annotationManager.disableReadOnlyMode();
        instance.Core.annotationManager.promoteUserToAdmin();
        instance.UI.disableElements(['annotationStyleEditButton','linkButton','annotationDeleteButton', 'signaturePanelButton',
            'annotationCommentButton','markReplaceTextToolButton','markInsertTextToolButton','freeTextToolButton','freeHandHighlightToolButton','freeHandToolButton','highlightToolButton','stickyToolButton']);
        instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => { 
            if(annotation.getCustomData("Object")){
                let json = JSON.parse(annotation.getCustomData("Object"))
                let object = props.objectsDataObject.data.find(row => row.ID == json.ObjectRequirement_ID)
                if(object){
                    const div = document.createElement('div'); 
                    div.innerHTML = `
                    <div style="display: flex; align-items: flex-start;">
                        <div style="flex: 1">
                            <p><b>Object Type:</b> ${object.ObjectType}</p>
                            <p><b>Name / Tag.No:</b> ${object.Object}</p>
                            <p><b>Description:</b> ${object.ObjectDescription ?? ''}</p>
                            <p><b>Org Unit:</b> ${object.OrgUnit}</p>
                        </div>
                    </div>`
                    return div;
                }
            }
            else{
                const div = document.createElement('div')
                div.appendChild(document.createTextNode(`Created by: ${annotation.Author}`)); 
                return div;
            } 
        });


        const deleteTagBtn = {
            type: 'actionButton',
            img: "ic-delete",
            dataElement: 'deleteTag',
            title: "Delete",
            onClick: () => {instance.Core.annotationManager.deleteAnnotation(lastSelectedAnnotation, { force: true });},
        };
        const addTagBtn = {
            type: 'actionButton',
            img: "icon-menu-add",
            dataElement: 'addTag',
            title: "Add Object/Tag",
            onClick: () => objLookupRef.value.showModal(),
        };
        const editTagBtn = {
            type: 'actionButton',
            img: "icon-tool-pen-line",
            dataElement: 'editTag',
            title: "Edit Object/Tag",
            onClick: () => objLookupRef.value.showModal(),
        };
        instance.UI.annotationPopup.add(deleteTagBtn);
        instance.UI.annotationPopup.add(addTagBtn);
        instance.UI.annotationPopup.add(editTagBtn);
        instance.Core.annotationManager.addEventListener('annotationSelected', (annots) => {
            if (annots && annots[0]) {
                instance.Core.Tools.Tool.enableAnnotationHoverCursors();
                switch(true) {
                case annots[0] instanceof instance.Core.Annotations.RectangleAnnotation:
                    lastSelectedAnnotation = annots[0];
                    instance.UI.enableElements([deleteTagBtn.dataElement]);
                    if(isReferencingObject(annots[0])){
                        instance.UI.disableElements([addTagBtn.dataElement])
                        instance.UI.enableElements([editTagBtn.dataElement])
                        annots.map(annot => { annot.NoResize = true, annot.NoMove = true })
                    }
                    else{
                        instance.UI.enableElements([addTagBtn.dataElement])
                        instance.UI.disableElements([editTagBtn.dataElement])
                    }
                    break;
                default:
                    instance.UI.disableElements([deleteTagBtn.dataElement,addTagBtn.dataElement]);
                    break;
                }
            }
        });

        instance.Core.Annotations.SelectionModel.setCustomHandlers(instance.Core.Annotations.BoxSelectionModel, {
            testSelection: (annotation, x, y, pageMatrix, zoom, rotation, { selectionModel, originalTestSelection }) => {
                if (annotation instanceof instance.Core.Annotations.RectangleAnnotation) {
                    return instance.Core.Annotations.SelectionAlgorithm.boundingRectTest(annotation, x, y, zoom);
                }
            }
        });

        instance.Core.documentViewer.getTool('AnnotationCreateRectangle').setStyles({
            StrokeColor: new instance.Core.Annotations.Color(0, 135, 238)
        })
    }

    async function searchForTags(){
        searchModalRef.value.hide();
        const pageText = await instance.Core.documentViewer.getDocument().loadPageText(1);
        const recognizer = new TagRecognizer(pageText, props.syntaxDataObject.data.map(syntax => { return { value: syntax.Name } }))
        const matches = await recognizer.getMatches();
        const tags_object = matches.map((t) => {
            return { Name: t.Tag, Object_ID: t.Object_ID }
        })
        const tags = tags_object.map(row => escapeRegex(row.Name)).join('|');
        let newAnnots = [];

        // let tags = props.objectsDataObject.data.map(row => escapeRegex(row.Object)).join('|');
        let tags_found = 0
        let objectRequirementsCreated = []
        let objectsCreated = []
        const { documentViewer, annotationManager, Search  } = instance.Core;
        documentViewer.textSearchInit(tags, Search.Mode.REGEX | Search.Mode.HIGHLIGHT , {
            fullSearch: true,
            onResult: (result) => {
                if (result.resultCode === Search.ResultCode.FOUND) {
                    const objectID = tags_object.find(tag => tag.Name == result.resultStr)?.Object_ID;
                    const newAnnotation = new instance.Core.Annotations.RectangleAnnotation();
                    newAnnotation.PageNumber = result.pageNum;
                    let rect = convertQuadsToRect(result.quads);
                    if(!rect){
                        return;
                    }
                    tags_found++;
                    newAnnotation.setRect(new instance.Core.Math.Rect(rect.x1-5,rect.y1-5,rect.x2+5,rect.y2+5))
                    let objectRequirementID = undefined;
                    if(objectID){
                        objectsCreated.push({ Object_ID: objectID, AnnotID: newAnnotation.Id });
                        objectRequirementID = props.objectsDataObject.data.find(row => row.Object_ID == objectID)?.ID
                        if(!objectRequirementID && !objectRequirementsCreated.find(row => row.Object_ID == objectID)){
                            objectRequirementsCreated.push({ Object_ID: objectID, AnnotID: newAnnotation.Id, Object: result.resultStr });
                        }
                        newAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 135, 238);
                    }
                    else{
                        newAnnotation.StrokeColor = new instance.Core.Annotations.Color(255, 165, 0);
                    }
                    newAnnotation.Author = result.resultStr
                    newAnnotation.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: objectRequirementID }) )
                    newAnnots.push(newAnnotation);
                }
            },
            onDocumentEnd: async () => {
                let modifiedAnnots = await createObjectRequirements(objectRequirementsCreated, newAnnots);
                createAnnotations(await annotationManager.exportAnnotations({annotationList:modifiedAnnots}), objectsCreated)
                o365alert(`${tags_found} Object(s) have been detected`,"info", { autohide: true, delay: 2500, slimVersion: true});
            }
        })
    }

    function convertQuadsToRect(quads) {
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        quads.forEach(q => {
            let quad = q.getPoints();
            minX = Math.min(minX,quad.x1,quad.x2,quad.x3,quad.x4);
            maxX = Math.max(maxX,quad.x1,quad.x2,quad.x3,quad.x4);
            minY = Math.min(minY,quad.y1,quad.y2,quad.y3,quad.y4);
            maxY = Math.max(maxY,quad.y1,quad.y2,quad.y3,quad.y4);
        });

        if(maxX - minX > 300 || maxY - minY > 300){
            return null
        }

        return {
            x2: maxX,
            x1: minX,
            y2: maxY,
            y1: minY
        }
    }

    function isReferencingObject(annotation){
        let json = annotation.getCustomData("Object");
        if(!json) { return false }
        let object = JSON.parse(json)
        return !!props.objectsDataObject.data.find(row => row.ID == object.ObjectRequirement_ID);
    }

    async function objectSelected(row){
        let obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        if(!obj){
            await props.objectsDataObject.createNew({ Object_ID: row._item.ID})
            await props.objectsDataObject.load();
            obj = props.objectsDataObject.data.find(obj => obj.Object == row.Name)?.ID;
        }
        lastSelectedAnnotation.Locked = false;
        lastSelectedAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 135, 238);
        lastSelectedAnnotation.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: obj }))
        lastSelectedAnnotation.Author = row._item.Name;
        instance.Core.annotationManager.trigger('annotationChanged', [[lastSelectedAnnotation], 'modify', {}]);
        instance.Core.annotationManager.updateAnnotation(lastSelectedAnnotation);
        instance.Core.annotationManager.redrawAnnotation(lastSelectedAnnotation);
    }

    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function createAnnotations(xfdfString, objectsCreated){
        const parser = new DOMParser();
        const xfdfElements = parser.parseFromString(xfdfString, 'text/xml')
        const annotations = xfdfElements.querySelector('annots').children;
        [].forEach.call(annotations, async (annotElement) => {
            const serializer = new XMLSerializer()
            let annot_xfdf = `<?xml version="1.0" encoding="UTF-8" ?>
                    <xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve">
                    <fields />
                    <add>${serializer.serializeToString(annotElement)}</add>
                    <modify />
                    <delete />
                    </xfdf>
            `
            let annotation = await instance.Core.annotationManager.importAnnotationCommand(annot_xfdf)
            if(annotation.length > 0){
                props.annotsDataObject.createNew({
                    AnnotID: annotation[0].Id,
                    Annot: annot_xfdf,
                    Page: annotation[0].getPageNumber()
                })
            }
        });
        instance.Core.documentViewer.refreshAll();
        instance.Core.documentViewer.updateView();
    }

    async function createObjectRequirements(objectToBeCreated, annots){
        await Promise.all(
            objectToBeCreated.map(async (row) => {
                await props.objectsDataObject.createNew({ Object_ID: row.Object_ID})
                await props.objectsDataObject.load();
                let objectRequirement_ID = props.objectsDataObject.data.find(obj => obj.Object_ID == row.Object_ID)?.ID
                annots.forEach(annot => {
                    if(annot.Author == row.Object){
                        annot.setCustomData("Object", JSON.stringify({ ObjectRequirement_ID: objectRequirement_ID }) )
                    }
                })
            })
        )
        return annots;
    }
</script>
